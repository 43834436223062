.main {
   flex-direction: column;
   margin-top: 10vh;
   align-items: center;
   flex: 1;

   &.auth {
      justify-content: center;
   }

   &.home {
      .banner {
         color: $c4;
         justify-content: space-around;
         min-height: 55vh;
         width: 100%;
         background-image:
            linear-gradient(#ffffff10 0%, #ffffff90 50%, #ffffff90 100%),
            url('../../assets/imgs/logo500.png'), ;
         background-attachment: fixed;
         background-repeat: no-repeat;
         background-position: center;
         flex-direction: column;
         text-align: center;
         background-position-y: 4em;

         @media (max-width:$mobile-size) {
            min-height: 35vh;
            background-position-y: 1em;
            background-size: contain;
         }

         h1 {
            text-shadow: 2px 2px 10px #000000a3;

         }

         h2 {}

         h2 {
            font-size: 2.75em;
            font-weight: 900;
            -webkit-text-stroke: 1px $c2;
            text-stroke: 1px $c2;
            letter-spacing: 1px;
            color: $c5;
            text-shadow: 2px 2px 10px #000000a3;

            @media (max-width:$mobile-size) {
               font-size: 1.75em;
            }
         }
      }

      .cards {
         flex-direction: column;
         margin: 2em;
         max-width: 1200px;
         width: 80%;
         gap: 2em;

         @media (max-width:$mobile-size) {
            width: 90%;
            gap: 1em;
            margin: 1em;

         }

         .card {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
            background: $gradient1;
            border-radius: 10px;
            gap: 1em;
            align-items: center;
            height: 380px;
            padding: 2em;
            position: relative;


            @media (max-width:$mobile-size) {
               flex-direction: column;

               height: fit-content;

               h2 {
                  text-align: center;
               }
            }

            img {
               background: $c1;
               border-radius: 10px;
               box-shadow: 0 0 10px #00000060;
            }

            section {
               flex-direction: column;
               gap: 1em;
            }

            h1 {
               text-align: center;
            }

            p {}
         }
      }
   }

   &.admin {
      padding: 1em;

      .week {
         padding: 1em;
         flex-direction: column;

         h3 {
            text-align: center;
            color: white;
            text-decoration: underline;
         }
      }
   }

   &.schedule {
      background-image: url(http://localhost:3001/static/media/wckollel.d1f2cae….png);
      background-size: 39%;
      background-position-x: center;
      background-repeat: repeat-y;
      padding: 2em;

      @media (max-width:$mobile-size) {
         padding: 1em;
      }

      .week {
         flex-direction: column;
         opacity: 0.95;


         h3 {
            text-align: center;
            color: $c4;
            text-decoration: underline;
         }
      }


   }

   &.donations {
      gap: 1em;

      h1 {
         text-align: center;
         color: $c4;
      }



      .donation-amount {
         flex-direction: column;
         align-items: center;
         gap: 2em;


         .btns {
            gap: 1em;
            flex-wrap: wrap;

            .btn {
               background: $c5;
               color: white;

               &.active {
                  background: $gradient1;
               }
            }
         }

         .input {
            position: relative;

            &::after {
               content: '$';
               color: white;
               position: absolute;
               left: 0.5em;
               bottom: 0;
               top: 0.1em;
               font-size: 1.75em;
            }

            input {
               padding-left: 2em;
               width: 300px;
               font-weight: 500;
               background: $gradient1;
               color: white;
               font-size: 1.55em;

               &::placeholder {
                  font-size: 0.75em;
               }

            }
         }

      }
   }

   &.thank-you {
      color: $c2;
      justify-content: center;
      background: $gradient4;


   }


}