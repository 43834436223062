.App {
   flex-direction: column;
   height: 100vh;
   width: 100vw;
   gap: 0;
   overflow: auto;
   position: relative;
}

.column {
   flex-direction: column;
}



::-webkit-scrollbar {
   width: 0.75em;
   height: 0.75em;
}

::-webkit-scrollbar-track {
   background: $c2;
}

::-webkit-scrollbar-thumb {
   background: $c3;
   border-radius: 5px;
}

::-webkit-calender-picker-indicator {
   background: white;
   padding: 0.5em;
   cursor: pointer;
   border-radius: 5px;
}


// :-webkit-autofill:hover,
// :-webkit-autofill:focus,
// :-webkit-autofill:active {
//    background-color: inherit !important;
//    -webkit-text-fill-color: #ffffff !important;
// }
