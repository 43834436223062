.form {
   flex-direction: column;
   gap: 1em;
   padding: 1em;
   background: $c2;
   min-width: 400px;

   @media (max-width:$mobile-size) {
      min-width: 350px;
   }

   h1 {
      color: white;
   }

   .error {
      text-align: center;
      color: $c-danger;
      height: 1.5em;
      display: flex;
      align-items: center;
      gap: 1em;
      font-size: 0.75em;
   }

   .input {
      position: relative;
      justify-content: space-between;
      align-items: baseline;

      >* {
         flex: 1;
      }

      label {
         color: $c1;
      }
   }

   &.donation {
      margin: 1em auto;
      background: $c3;
      max-width: 400px;
      height: 0px;
      padding: 0;
      transition: all 0.5s ease-out;
      overflow: hidden;

      &.open {
         height: 65vh;
         padding: 1em;

      }

      .overlay {
         pointer-events: all;
         position: fixed;
         top: 0;
         left: 0;
         width: 100vw;
         height: 100%;
         background: rgba(0, 0, 0, 0.5);
         display: flex;
         align-items: center;
         justify-content: center;
         z-index: 1000;
      }



      h4 {
         color: $c4;
         text-align: center;
      }

      input,
      select {
         font-weight: 500;
         background: $c4;
         color: white;

         &::placeholder {
            color: #B8B8B8;
            text-align: start;
         }

      }
   }
}