.loader {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 999;

   #sandwatchloader {
      @keyframes lqt {

         0%,
         100% {
            background-image: linear-gradient($c1 , 40px, transparent 0);
            background-position: 0% 0px;
         }

         50% {
            background-image: linear-gradient($c1 , 40px, transparent 0);
            background-position: 0% 40px;
         }

         51% {
            background-image: linear-gradient($c1 , 40px, transparent 0);
            background-position: 0% -40px;
         }
      }

      @keyframes lqb {
         0% {
            background-image: linear-gradient($c1 , 40px, transparent 0);
            background-position: 0 40px;
         }

         100% {
            background-image: linear-gradient($c1 , 40px, transparent 0);
            background-position: 0 -40px;
         }
      }

      @keyframes spinx {

         0%,
         49% {
            transform: rotate(0deg);
            background-position: 50% 36px;
         }

         51%,
         98% {
            transform: rotate(180deg);
            background-position: 50% 4px;
         }

         100% {
            transform: rotate(360deg);
            background-position: 50% 36px;
         }
      }

      box-sizing: border-box;
      display: inline-block;
      width: 50px;
      height: 80px;
      border-top: 5px solid #fff;
      border-bottom: 5px solid #fff;
      background: linear-gradient($c1, 30px, transparent 0) no-repeat;
      background-size: 2px 40px;
      background-position: 50% 0px;
      animation: spinx 5s linear infinite;
      left: calc(50% - 25px);

      &:before,
      &:after {
         content: "";
         width: 40px;
         left: 50%;
         height: 35px;
         position: absolute;
         top: 0;
         transform: translatex(-50%);
         background: rgba(255, 255, 255, 0.4);
         border-radius: 0 0 20px 20px;
         background-size: 100% auto;
         background-repeat: no-repeat;
         background-position: 0 0px;
         animation: lqt 5s linear infinite;
      }

      &:after {
         top: auto;
         bottom: 0;
         border-radius: 20px 20px 0 0;
         animation: lqb 5s linear infinite;
      }
   }

   &#money {
      transform: translateZ(1px);

      &:after {
         content: '$';
         display: inline-block;
         width: 48px;
         height: 48px;
         border-radius: 50%;
         text-align: center;
         line-height: 40px;
         font-size: 32px;
         font-weight: bold;
         background: #FFD700;
         color: #DAA520;
         border: 4px double;
         box-sizing: border-box;
         box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .1);
         animation: coin-flip 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }

      @keyframes coin-flip {

         0%,
         100% {
            animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
         }

         0% {
            transform: rotateY(0deg);
         }

         50% {
            transform: rotateY(1800deg);
            animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
         }

         100% {
            transform: rotateY(3600deg);
         }
      }
   }
}